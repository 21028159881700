<template>
  <div>
    <HintMessage
      v-if="showSelectedMetricHint"
      :isRequiredMetricSelected="showSelectedMetricHint"
      :isNoDataHint="showNoDataHint"
    />

    <div :style="{ minHeight: minHeight }">
      <ScatterChart
        v-if="!showSelectedMetricHint && !showNoDataHint"
        :options="scatterChartOptions"
        :data="scatterChartData"
      />
    </div>
  </div>
</template>

<script>
import { Scatter } from 'vue-chartjs';
import HintMessage from '@/components/HintMessage.vue';

import {
  API_ALL_METRIC_STATUSES_BY_DEVICE_SESSION,
  API_ALL_EVENTS_BY_DEVICE_SESSION,
} from '@/helper/api.js';

import { mapState } from 'vuex';

import { Chart as ChartJS, Filler, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { enUS } from 'date-fns/locale';
ChartJS.register(Filler, TimeScale);

export default {
  name: 'DeviceChart',

  components: {
    ScatterChart: Scatter,
    HintMessage,
  },

  props: {
    selectedMetricOrEvent: Array,
    selectedDeviceSession: Object,
    isLegendDisplayed: Boolean,
    minHeight: {
      type: String,
      default: '500px',
    },
    isTooltipExternal: Boolean,
  },

  data() {
    return {
      apiData: null,
      showSelectedMetricHint: true,
      showNoDataHint: false,

      scatterChartData: {
        datasets: [],
      },
    };
  },

  computed: {
    ...mapState(['selectionsInOneDevice']),

    scatterChartOptions() {
      let tooltipEnabled;
      let tooltipExternal;

      if (this.isTooltipExternal === false) {
        tooltipEnabled = true;
        tooltipExternal = null;
      } else {
        tooltipEnabled = false;
        tooltipExternal = this.externalTooltipHandler;
      }

      return {
        responsive: true,
        maintainAspectRatio: false,

        interaction: {
          intersect: false,
          mode: this.isTooltipExternal ? 'nearest' : 'x',
        },

        scales: {
          y: {
            min: -5,
            max: 105,
            display: false,
          },

          x: {
            min: new Date(this.selectedDeviceSession.startDate).getTime(),
            max: new Date(this.selectedDeviceSession.endDate).getTime(),
            ticks: {
              color: 'white',
            },

            adapters: {
              date: {
                locale: enUS,
              },
            },

            type: 'time',
            time: {
              unit: 'minute',
            },
          },
        },

        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: this.isLegendDisplayed,
            labels: {
              color: 'white',
              filter: (item) => item.text !== 'none', // hide specific legend for bool
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const dataset =
                  this.scatterChartData.datasets[context.datasetIndex];
                if (!dataset.hoverData) {
                  return null;
                }
                const label = dataset.label || '';
                const value = dataset.hoverData[context.dataIndex];
                return label + ': ' + value;
              },
            },
            enabled: tooltipEnabled,
            position: 'nearest',
            external: tooltipExternal,
          },
        },
      };
    },
  },

  watch: {
    selectedMetricOrEvent: {
      handler: 'updateChartData',
      deep: true,
    },
  },

  mounted() {
    this.fetchAllMetricAndEventByDeviceSession().then(() => {
      this.updateChartData();
    });
  },

  methods: {
    async fetchAllMetricAndEventByDeviceSession() {
      const startDate = this.selectedDeviceSession.startDate;
      const endDate = this.selectedDeviceSession.endDate;
      const projectName = this.$provider.selection.site.projectName;
      const siteName = this.$provider.selection.site.siteName;
      const deviceSessionId = this.selectedDeviceSession._id;

      return Promise.all([
        this.$ynapse.GET(
          `${API_ALL_METRIC_STATUSES_BY_DEVICE_SESSION}?startDate=${startDate}&endDate=${endDate}&projectName=${projectName}&siteName=${siteName}&deviceSessionId=${deviceSessionId}`,
        ),
        this.$ynapse.GET(
          `${API_ALL_EVENTS_BY_DEVICE_SESSION}?startDate=${startDate}&endDate=${endDate}&projectName=${projectName}&siteName=${siteName}&deviceSessionId=${deviceSessionId}`,
        ),
      ])
        .then(([metricsRes, eventsRes]) => {
          this.apiData = { metrics: metricsRes.data, events: eventsRes.data };
        })
        .catch((error) => {
          console.error(
            'Error fetching all metric statuses by device session:',
            error,
          );
        });
    },

    filterData(transformedData) {
      const selectedMetricsOrEvents = this.selectedMetricOrEvent.map(
        (item) => item.name,
      );

      const filterByType = (data, typeKey) =>
        transformedData[data].filter((item) =>
          selectedMetricsOrEvents.includes(item[typeKey]),
        );

      const matchedMetrics = filterByType('metrics', 'metricName');
      const matchedEvents = filterByType('events', 'eventType');

      return { matchedMetrics, matchedEvents };
    },

    updateChartData() {
      if (!this.apiData) {
        return;
      }

      let datasets = [];

      this.apiData.metrics.forEach((metric) => {
        let selectedMetric = this.selectedMetricOrEvent.find(
          (i) => i.category == 'metric' && i.name == metric.metricName,
        );
        if (selectedMetric) {
          metric.type = selectedMetric.type;
        }
      });

      const originalApiData = JSON.parse(JSON.stringify(this.apiData));

      if (this.selectedMetricOrEvent.length === 0) {
        this.showSelectedMetricHint = true;
      }

      if (this.selectedMetricOrEvent.length > 0) {
        const highValueMetrics = originalApiData.metrics
          .filter((metric) =>
            metric.metricStatuses.some(
              (metricStatus) => metricStatus.value > 100,
            ),
          )
          .map((metric) => {
            return {
              metricName: metric.metricName,
              metricStatuses: metric.metricStatuses.filter(
                (metricStatus) => metricStatus.value > 100,
              ),
            };
          });

        let boolMetricCount = 0;
        const transformApiData = {
          events: originalApiData.events,
          metrics: originalApiData.metrics.map((metric) => {
            if (metric.type === 'boolean') {
              boolMetricCount++;
              let offset = 6 * (boolMetricCount - 1);
              return {
                ...metric,
                metricStatuses: metric.metricStatuses.map((metricStatus) => {
                  if (metricStatus.value === true) {
                    metricStatus.value = 5 + offset;
                  } else if (metricStatus.value === false) {
                    metricStatus.value = 1 + offset;
                  } else if (metricStatus.value === null) {
                    metricStatus.value = 0;
                  }
                  return metricStatus;
                }),
              };
            }

            if (
              highValueMetrics.some(
                (highMetric) => highMetric.metricName === metric.metricName,
              )
            ) {
              const highestValue = Math.max(
                ...metric.metricStatuses.map(
                  (metricStatus) => metricStatus.value,
                ),
              );

              let scalingFactor = 1;
              if (highestValue > 100) {
                scalingFactor = 100 / highestValue;
              }

              const scaledStatuses = metric.metricStatuses.map(
                (metricStatus) => ({
                  ...metricStatus,
                  value: Math.min(metricStatus.value * scalingFactor, 100), // Cap the scaled value at 100
                  hoverValue: metricStatus.value,
                }),
              );

              return { ...metric, metricStatuses: scaledStatuses };
            } else {
              return metric;
            }
          }),
        };

        const matchedData = this.filterData(transformApiData);

        this.showNoDataHint =
          matchedData.matchedMetrics.length === 0 &&
          matchedData.matchedEvents.length === 0;

        if (matchedData.matchedMetrics.length > 0) {
          this.showSelectedMetricHint = false;
          boolMetricCount = 0;

          matchedData.matchedMetrics.forEach((metric) => {
            const selection = this.selectionsInOneDevice.selections.find(
              (s) => s.name === metric.metricName,
            );

            let data = metric.metricStatuses.map((metricStatus) => ({
              x: new Date(metricStatus.startDate).getTime(),
              y: metricStatus.value,
            }));

            let hoverData = metric.metricStatuses.map((metricStatus) =>
              metricStatus.hoverValue
                ? metricStatus.hoverValue
                : metricStatus.value,
            );

            const lastStatus =
              metric.metricStatuses[metric.metricStatuses.length - 1];

            data.push({
              x: lastStatus.endDate
                ? new Date(lastStatus.endDate).getTime()
                : Date.now(),
              y: lastStatus.value,
            });

            hoverData.push(
              lastStatus.hoverValue ? lastStatus.hoverValue : lastStatus.value,
            );

            if (metric.type === 'number') {
              const dataset = {
                label: metric.metricName,
                data,
                hoverData,
                fill: false,
                backgroundColor: selection.color,
                borderColor: selection.color,
                borderWidth: 2,
                pointRadius: 0,
                showLine: true,
              };
              datasets.push(dataset);
            }

            if (metric.type === 'boolean') {
              boolMetricCount++;
              let offset = 6 * (boolMetricCount - 1);
              const dataset = {
                label: metric.metricName,
                data,
                hoverData,
                fill: true,
                stepped: true,
                backgroundColor: selection.color,
                borderColor: selection.color,
                borderWidth: 2,
                pointRadius: 0,
                showLine: true,
              };
              datasets.push(dataset);

              const dataset2 = {
                label: 'none',
                data: [
                  { x: this.selectedDeviceSession.startDate, y: 5 + offset },
                  { x: this.selectedDeviceSession.endDate, y: 5 + offset },
                ],
                fill: true,
                backgroundColor: '#111111',
                borderColor: '#111111',
                borderWidth: 2,
                pointRadius: 0,
                showLine: true,
              };
              datasets.push(dataset2);
            }

            if (metric.type === 'string') {
              // todo in the future
            }
          });
        }

        if (matchedData.matchedEvents.length > 0) {
          this.showSelectedMetricHint = false;

          matchedData.matchedEvents.forEach((e) => {
            const selection = this.selectionsInOneDevice.selections.find(
              (s) => s.name === e.eventType,
            );

            const dataset = {
              label: e.eventType,
              data: e.events.map((ev) => ({
                x: new Date(ev.timestamp).getTime(),
                y: 100,
              })),
              hoverData: e.events.map((ev) => {
                let content = [];
                for (let key in ev.details) {
                  if (key != 'position' && key != 'rotation') {
                    content.push(`${key}=${ev.details[key]}`);
                  }
                }
                return content.join(', ');
              }),
              backgroundColor: selection.color,
            };
            datasets.push(dataset);
          });
        }

        this.scatterChartData = {
          datasets: datasets,
        };
      }
    },

    // we will discuss on Wednesday to see if we need to keep the below functions
    // convertLabelToArray(label) {
    //   const labelParts = label.split('.');
    //   return labelParts.map((part) => part.trim());
    // },

    // convertValue(value) {
    //   if (typeof value === 'number') {
    //     return value;
    //   }

    //   const valueParts = value.split(' ');
    //   console.log('valueParts', valueParts);
    //   console.log('value', value);
    //   return valueParts.map((part) => part.trim());
    // },

    // getLastTwoStrings(string) {
    //   const stringParts = string.split('.');

    //   if (stringParts.length <= 1) {
    //     return string;
    //   }

    //   return stringParts.slice(-2).join('.');
    // },

    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.6)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    },

    externalTooltipHandler(context) {
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach((title) => {
          const tr = document.createElement('tr');
          tr.style.borderWidth = 0;

          const th = document.createElement('th');
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement('span');
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = '2px';
          span.style.marginRight = '4px';
          span.style.height = '10px';
          span.style.width = '10px';
          span.style.display = 'inline-block';

          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;

          const td = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
      const tooltipMinDistance = tooltipEl.offsetWidth / 2;

      window.addEventListener('mousemove', function (event) {
        let mouseX = event.clientX;

        if (mouseX < tooltipMinDistance) {
          tooltipEl.style.left = tooltipMinDistance + 20 + 'px';
        } else if (window.innerWidth - mouseX < tooltipMinDistance) {
          tooltipEl.style.left =
            window.innerWidth - tooltipMinDistance - 20 + 'px';
        } else {
          tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        }
      });

      tooltipEl.style.opacity = 1;
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    },
  },
};
</script>
