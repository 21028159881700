<template>
  <div v-if="selectedMetric">
    <header>
      <div class="subheading">
        <router-link :to="{ name: 'system-metrics' }">
          &lt; {{ categoryName }}
        </router-link>
      </div>

      <div class="header-container">
        <h2>{{ selectedMetric.title || selectedMetric.name }}</h2>
        <DateTimeFilter
          @date-range-updated="handleDateRangeSelected"
          @date-option-updated="handleDateOptionSelected"
        />
      </div>
    </header>

    <HintMessage
      v-if="
        showLoadingMessage || showNoDataHint || showRequiredFieldsHintDetails
      "
      :isLoadingHint="showLoadingMessage"
      :isNoDataHint="showNoDataHint"
      :isRequiredFieldsHintDetails="showRequiredFieldsHintDetails"
    />

    <section
      v-if="
        !showRequiredFieldsHintDetails && !showNoDataHint && !showLoadingMessage
      "
    >
      <div v-if="selectedMetric.type === 'number'" class="d-flex">
        <div class="temperature-info">
          <div class="subheading">Low</div>
          <h2>{{ low }}{{ selectedMetric.unit }}</h2>
        </div>
        <div class="temperature-info">
          <div class="subheading">High</div>
          <h2>{{ high }}{{ selectedMetric.unit }}</h2>
        </div>
      </div>
    </section>

    <div class="line-chart mx-5">
      <LineChart
        v-if="
          !showLoadingMessage &&
          !showNoDataHint &&
          !showRequiredFieldsHintDetails
        "
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { API_ONE_METRIC } from '@/helper/api.js';
import { Chart as ChartJS, Filler } from 'chart.js';
import { Line } from 'vue-chartjs';
import { enUS } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import 'chartjs-adapter-date-fns';
import DateTimeFilter from '@/components/device/DateTimeFilter.vue';
import HintMessage from '@/components/HintMessage.vue';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(Filler, zoomPlugin);

export default {
  name: 'SystemMetricDetails',
  components: {
    LineChart: Line,
    DateTimeFilter,
    HintMessage,
  },

  data() {
    return {
      selectedDateOption: 'last5days',
      startDate: null,
      endDate: null,
      apiData: [],
      low: '',
      high: '',
      showLoadingMessage: false,
      showNoDataHint: false,
      showRequiredFieldsHintDetails: false,
      booleanHoverData: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              minUnit: 'minute',
            },
          },
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.1,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
        },
        elements: {
          point: {
            radius: 0, // hide the dots
          },
        },
        parsing: {
          xAxisKey: 'time',
          yAxisKey: 'value',
        },
      },
    };
  },

  computed: {
    selectedMetric() {
      return this.$provider.selection.systemMetric;
    },

    categoryName() {
      return this.selectedMetric ? this.selectedMetric.category : null;
    },
  },

  watch: {
    '$provider.cache.metrics'() {
      if (!this.selectedMetric) {
        this.$provider.selectMetric(this.$route.params.metricName);
      }
    },

    selectedMetric(value) {
      if (value) {
        this.fetchOneMetric();
      } else {
        this.apiData = [];
      }
    },
  },

  // mounted () {
  //   if (!this.selectedMetric && this.$provider.cache.metrics != null) {
  //     this.$provider.selectMetric(this.$route.params.metricName);
  //   }
  // },

  methods: {
    formatNameFromURL(name) {
      return name.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    },

    formatTZ(time) {
      return toZonedTime(time, this.$provider.selection.site.timeZone);
    },

    async fetchOneMetric() {
      if (!this.startDate || !this.endDate) {
        this.apiData = [];
        this.showRequiredFieldsHintDetails = true;
        return;
      }

      try {
        this.showLoadingMessage = true;
        this.showRequiredFieldsHintDetails = false;
        const res = await this.fetchDataFromAPI();
        this.apiData = res.data;
        console.log(this.apiData);

        this.showLoadingMessage = false;

        if (this.apiData.length === 0) {
          this.showNoDataHint = true;
          return;
        }

        this.showNoDataHint = false;
        this.processChartData();
      } catch (error) {
        console.log('Error fetching one metric', error);
      }
    },

    async fetchDataFromAPI() {
      const startDate = this.startDate;
      const endDate = this.endDate;

      return await this.$ynapse.GET(API_ONE_METRIC, {
        siteId: this.$provider.selection.site.siteId,
        metricName: this.selectedMetric.name,
        startDate,
        endDate,
      });
    },

    processChartData() {
      let formattedApiData = this.formatApiData();
      this.setChartData(formattedApiData);
      this.setLowHighValues(formattedApiData);
    },

    formatApiData() {
      let formattedApiData = [];
      if (this.selectedMetric.type === 'number') {
        for (let i = 0; i < this.apiData.length; i++) {
          let m = this.apiData[i];
          let m2 = this.apiData[i + 1];

          formattedApiData.push({
            value: m.value,
            time: this.formatTZ(m.startDate),
            color: m.color,
          });
          if (
            m2 &&
            m2.value != null &&
            new Date(m2.startDate) - new Date(m.endDate) > 10000
          ) {
            // There's a gap in the data
            formattedApiData.push({
              value: m.value,
              time: this.formatTZ(m.endDate),
              color: m.color,
            });
            formattedApiData.push({
              value: null,
              time: this.formatTZ(new Date(m.endDate).getTime() + 5000),
              color: m.color,
            });
          }
        }
        let lastStatus = this.apiData[this.apiData.length - 1];
        formattedApiData.push({
          value: lastStatus.value,
          time: this.formatTZ(lastStatus.endDate || this.endDate),
          color: lastStatus.color,
        });
      }

      if (this.selectedMetric.type === 'boolean') {
        for (let i = 0; i < this.apiData.length; i++) {
          let m = this.apiData[i];
          let m2 = this.apiData[i + 1];

          let obj = {
            raw: m.value,
            time: this.formatTZ(m.startDate),
            color: m.color,
          };

          if (obj.raw === true) {
            obj.value = 10;
          } else if (obj.raw === false) {
            obj.value = 3;
          } else {
            obj.value = 1;
          }

          formattedApiData.push(obj);
          console.log('before-formattedApiData', formattedApiData);

          if (
            m2 &&
            m2.value != null &&
            new Date(m2.startDate) - new Date(m.endDate) > 10000
          ) {
            // There's a gap in the data
            formattedApiData.push({
              raw: m.value,
              value: obj.value,
              time: this.formatTZ(m.endDate),
              color: m.color,
            });

            formattedApiData.push({
              raw: null,
              value: 1,
              time: this.formatTZ(new Date(m.endDate).getTime() + 5000),
              color: '#9D9D9D',
            });
          }
        }
        let lastStatus = this.apiData[this.apiData.length - 1];
        let obj = {
          raw: lastStatus.value,
          time: this.formatTZ(lastStatus.endDate || this.endDate),
          color: lastStatus.color,
        };

        if (obj.raw === true) {
          obj.value = 10;
        } else if (obj.raw === false) {
          obj.value = 3;
        } else {
          obj.value = 1;
        }

        formattedApiData.push(obj);

        this.booleanHoverData = formattedApiData;

        console.log('formattedApiData', formattedApiData);
        console.log('this.apiData', this.apiData);
        console.log('this.booleanHoverData', this.booleanHoverData);
      }

      if (this.selectedMetric.type === 'string') {
        for (let i = 0; i < this.apiData.length; i++) {
          let m = this.apiData[i];
          let m2 = this.apiData[i + 1];

          formattedApiData.push({
            value: m.value,
            time: this.formatTZ(m.startDate),
            color: m.color,
          });
          if (
            m2 &&
            m2.value != null &&
            new Date(m2.startDate) - new Date(m.endDate) > 10000
          ) {
            // There's a gap in the data
            formattedApiData.push({
              value: m.value,
              time: this.formatTZ(m.endDate),
              color: m.color,
            });
            formattedApiData.push({
              value: null,
              time: this.formatTZ(new Date(m.endDate).getTime() + 5000),
              color: m.color,
            });
          }
        }
        let lastStatus = this.apiData[this.apiData.length - 1];
        formattedApiData.push({
          value: lastStatus.value,
          time: this.formatTZ(lastStatus.endDate || this.endDate),
          color: lastStatus.color,
        });
      }
      return formattedApiData;
    },

    setChartData(formattedApiData) {
      if (this.selectedMetric.type === 'number') {
        this.chartData = {
          labels: formattedApiData.map((m) => m.time),
          datasets: [
            {
              data: formattedApiData,
              segment: {
                borderColor: (ctx) => {
                  return ctx.p1.raw.color;
                },
              },
              type: 'line',
              borderWidth: 2,
            },
          ],
        };
      }

      if (this.selectedMetric.type === 'boolean') {
        this.chartData = {
          labels: formattedApiData.map((m) => m.time),
          datasets: [
            {
              data: formattedApiData.map((m) => m.value),
              borderWidth: 0,
              backgroundColor: formattedApiData.map((m) => m.color),
              segment: {
                backgroundColor: (ctx) => {
                  return ctx.p0.options.backgroundColor;
                },
              },
              stepped: 'before',
              fill: true,
            },
          ],
        };

        this.chartOptions = {
          responsive: true,
          interaction: {
            mode: 'nearest',
            axis: 'xy',
            intersect: false,
          },

          scales: {
            x: {
              type: 'time',
              time: {
                minUnit: 'minute',
              },
              adapters: {
                date: {
                  locale: enUS,
                },
              },
            },
            y: {
              display: false,
              min: 0,
              max: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.125,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'x',
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const dataIndex = context.dataIndex;
                  return [`${this.booleanHoverData[dataIndex].raw}`];
                },
              },
            },
          },
          elements: {
            point: {
              radius: 0, // hide the dots
            },
          },
        };
      }

      if (this.selectedMetric.type === 'string') {
        // it needs to be modified, temporarily used type number data
        this.chartData = {
          labels: formattedApiData.map((m) => m.time),
          datasets: [
            {
              data: formattedApiData,
              segment: {
                borderColor: (ctx) => {
                  return ctx.p1.raw.color;
                },
              },
              type: 'line',
              borderWidth: 2,
            },
          ],
        };
      }
    },

    setLowHighValues(formattedApiData) {
      const { low, high } = formattedApiData.reduce(
        (acc, currentItem) => {
          const value = currentItem.value;

          if (value !== null) {
            if (acc.low === null || value < acc.low) {
              acc.low = value;
            }

            if (acc.high === null || value > acc.high) {
              acc.high = value;
            }
          }

          return acc;
        },
        { low: null, high: null },
      );

      if (low !== null && high !== null) {
        this.low = low;
        this.high = high;
      }
    },

    handleDateRangeSelected(dateRange) {
      this.startDate = dateRange.from;
      this.endDate = dateRange.to;
      this.fetchOneMetric();
    },

    handleDateOptionSelected(option) {
      this.selectedDateOption = option;
    },
  },
};
</script>

<style scoped>
header {
  padding: 20px;
}

.subheading {
  font-size: 14px;

  & > a {
    color: var(--white);
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 600px;
}

.temperature-info {
  padding: 0 20px;
  margin-right: 48px;
}

.temperature-desc {
  color: var(--light-grey);
  padding: 0 20px;
  font-size: 12px;
}

.line-chart {
  padding: 20px;
  margin-top: 20px;
  max-height: 70vh;
}

.select {
  background-color: var(--black);
  border: 1px solid var(--light-grey);
  color: var(--white);
  padding: 6px;
  margin: 10px;
  border-radius: 10px;
}
</style>
