<template>
  <div class="wrapper" @click="handleClick">
    <div class="header">
      <div>{{ siteKitName || 'Archive' }}</div>
      <synapse-timer type="clock" :timeZone="timeZone" />
    </div>
    <div class="site">{{ siteName }}</div>
    <div class="info" v-if="statusRoute">
      <synapse-metric :route="statusRoute" dot displayMode="text"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteSelection',
  props: {
    projectName: String,
    siteName: String,
    siteKitName: String,
    siteKitId: String,
    siteId: String,
    timeZone: String,
  },

  computed: {
    statusRoute() {
      return this.siteKitId ? `/sites/${this.siteKitId}/status` : null;
    },
  },

  methods: {
    handleClick() {
      const selectedSite = {
        siteId: this.siteId,
        siteName: this.siteName,
        projectName: this.projectName,
        timeZone: this.timeZone,
        kitId: this.siteKitId,
      };
      this.$emit('site-selected', selectedSite);
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--container);
  border-radius: 10px;
  padding: 18px;
  font-size: 12px;
  margin-right: 7px;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 5px 5px 10px var(--light-grey);
    cursor: pointer;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  color: var(--detail);
}

.site {
  font-size: 18px;
  color: var(--header);
}

.info {
  display: flex;
  align-items: baseline;
  color: var(--detail);
}
</style>
