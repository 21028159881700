<template>
  <div class="wrapper">
    <div class="project-name">{{ projectName }}</div>
    <div class="site-container">
      <SiteSelection
        class="site-selection"
        v-for="site in sites"
        :key="site.id"
        :siteName="site.name"
        :siteId="site.id"
        :siteKitName="site.kitName"
        :siteKitId="site.kitId"
        :timeZone="site.timezone"
        :projectName="site.projectName"
        @site-selected="(val) => $emit('site-selected', val)"
      />
    </div>
  </div>
</template>

<script>
import SiteSelection from '@/components/site-overview/SiteSelection.vue';

export default {
  name: 'ProjectSelection',
  components: {
    SiteSelection,
  },

  props: {
    projectName: String,
    sites: Array,
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.project-name {
  min-width: 10rem;
  margin: 20px;
  font-size: 22px;
}

.site-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin: 14px;
  flex: 1;
}

.site-selection {
  display: grid;
  grid-template-rows: auto;
  gap: 18px;
  min-width: 200px;
}
</style>
