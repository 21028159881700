<template>
  <main class="position-relative">
    <div class="position-absolute px-3" style="top: 0; right: 0">
      <div
        style="font-size: 36px; cursor: pointer"
        v-if="showCloseButton"
        @click="$emit('close')"
      >
        X
      </div>
    </div>
    <h2 class="m-3">{{ title }}</h2>
    <ProjectSelection
      v-for="project in groupedProjects"
      :key="project.id"
      :projectName="project.projectName"
      :sites="project.sites"
      @site-selected="handleSiteSelected"
    />
  </main>
</template>

<script>
import ProjectSelection from '@/components/site-overview/ProjectSelection.vue';
export default {
  name: 'SiteWithTitle',
  components: {
    ProjectSelection,
  },
  props: {
    title: {
      type: String,
      default: 'Choose a Server',
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sites () {
      return this.$provider.cache.sites || [];
    },

    groupedProjects() {
      const projectMap = new Map();
      this.sites.forEach((site) => {
        const projectKey = site.projectName;
        if (!projectMap.has(projectKey)) {
          projectMap.set(projectKey, { projectName: projectKey, sites: [] });
        }
        projectMap.get(projectKey).sites.push(site);
      });

      return [...projectMap.values()].sort((a, b) =>
        a.projectName.localeCompare(b.projectName),
      );
    },
  },

  methods: {
    handleSiteSelected(selectedSiteData) {
      this.$provider.selection.site = selectedSiteData;

      this.$emit('site-selected', selectedSiteData);

      this.$router.push({
        name: 'dashboard',
        params: {
          projectName: selectedSiteData.projectName,
          siteName: selectedSiteData.siteName,
        },
      });
    },
  },
};
</script>
