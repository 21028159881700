<template>
  <div
    :class="[
      messageClasses,
      {
        blink: isLoadingHint,
        'no-data': [isNoDataHint, isNoLayoutHint],
      },
    ]"
  >
    {{ displayedMessage }}
  </div>
</template>

<script>
export default {
  name: 'HintMessage',
  props: {
    isLoadingHint: Boolean,
    isRequiredFieldsHint: Boolean,
    isRequiredFieldsHintDetails: Boolean,
    isNoDataHint: Boolean,
    isNoLayoutHint: Boolean,
    isRequiredMetricSelected: Boolean,
    isPreparingCSV: Boolean,
    isNoServerSession: Boolean,
    isDashboardStillGenerating: Boolean,
    displayName: String,
  },

  computed: {
    messageClasses() {
      return {
        'text-center': true,
        'mt-3': true,
      };
    },
    displayedMessage() {
      switch (true) {
        case this.isLoadingHint:
          return 'loading';
        case this.isRequiredFieldsHint:
          return 'Required fields to show data: from, to, and at least one event type';
        case this.isRequiredFieldsHintDetails:
          return 'Required fields: from, to';
        case this.isNoDataHint:
          return `No data matching the selected criteria ${
            this.displayName || ''
          }`;
        case this.isNoLayoutHint:
          return 'Failed to get layout when fetching data.';
        case this.isRequiredMetricSelected:
          return 'Select metrics to see data';
        case this.isPreparingCSV:
          return 'Preparing CSV file';
        case this.isNoServerSession:
          return 'There is no server session';
        case this.isDashboardStillGenerating:
          return 'A dashboard you requested previously is still being generated; please wait until it is generated, then try again';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 4.2s infinite;
}

.no-data {
  color: #2094fa;
}
</style>
