var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.messageClasses,
    {
      blink: _vm.isLoadingHint,
      'no-data': [_vm.isNoDataHint, _vm.isNoLayoutHint],
    },
  ]},[_vm._v(" "+_vm._s(_vm.displayedMessage)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }