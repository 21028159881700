<template>
  <div class="w-100 h-100 p-4 d-flex flex-column">
    <section class="d-flex justify-content-between">
      <div>
        <h2>Monitoring</h2>
        <div class="subheading">
          <span v-if="activeSession">
            {{
              new Date(activeSession.startDate).toLocaleDateString('en-US', {
                timeZone,
              })
            }}:
            {{
              new Date(activeSession.startDate).toLocaleTimeString('en-US', {
                timeZone,
              })
            }}
            -
            {{
              new Date(activeSession.endDate).toLocaleTimeString('en-US', {
                timeZone,
              })
            }}
          </span>
          <span v-else> Live data </span>
        </div>
      </div>

      <ServerSessionPicker
        label="Jump to date"
        :siteId="selectedSite.siteId"
        :timeZone="timeZone"
        v-model="activeSession"
        class="mr-1"
      />
    </section>

    <b-spinner
      v-if="fetchingSessionData"
      variant="light"
      class="mx-auto mt-5"
      style="width: 10rem; height: 10rem"
    />
    <div v-else-if="activeSession && sessionData" class="flex-grow-1 mt-3">
      <ExperienceTimeline
        v-model="currentTime"
        :startDate="new Date(activeSession.startDate)"
        :endDate="new Date(activeSession.endDate)"
        :timeZone="timeZone"
        :events="importantEvents"
        @spectator-selected="selectSpectator"
        class="mx-2"
      />
      <section class="d-flex justify-content-around mt-3">
        <div class="monitoring-table-container">
          <div class="p-2 d-flex justify-content-between w-100">
            <span class="text-content">
              {{ activeUsers.length }} spectators
            </span>
            <input
              type="text"
              class="search w-50"
              v-model="searchText"
              placeholder="Filter by name, device or group"
            />
          </div>
          <table v-if="activeUsers.length" class="w-100">
            <tr class="text-center table-head">
              <th>ID</th>
              <!-- <th>Status</th> -->
              <th>Group #</th>
              <th>Start</th>
              <th>End</th>
            </tr>
            <tr
              v-for="(user, index) in activeUsers"
              :key="user.name"
              :class="`cursor-pointer table-text ${userRowClass(
                user,
                index,
                selectedUserId,
              )}`"
              @mouseover="() => (selectedUserId = user.name)"
              @click="navigateToUser(user.name)"
            >
              <td>{{ user.name }}</td>
              <!-- <td>{{'unknown'}}</td> -->
              <td>{{ user.sessions[0].group || 'None' }}</td>
              <td>
                {{
                  new Date(user.sessions[0].startDate).toLocaleTimeString(
                    'en-US',
                    { timeZone },
                  )
                }}
              </td>
              <td>
                {{
                  new Date(user.sessions[0].endDate).toLocaleTimeString(
                    'en-US',
                    { timeZone },
                  )
                }}
              </td>
            </tr>
          </table>
          <div class="hint" v-else-if="searchText">
            No users found matching "{{ searchText }}"
          </div>
          <div class="hint" v-else-if="sessionData.users.length">
            Search for users or drag the timeline to have relevant users appear
            here
          </div>
        </div>

        <MapView
          v-if="sessionData.layout != null"
          ref="map"
          :layoutData="sessionData.layout"
          displayMode="fit"
          class="flex-grow-1 map ml-2"
          style="max-height: 594px"
          :anchorY="0"
          :dataRate="10"
          :highlightID="selectedUserId"
          @spectator-selected="selectSpectator"
        />
      </section>
    </div>
    <div v-else-if="monitoringRoute" class="mt-3">
      <!-- <ExperienceTimeline
        v-model="currentTime"
        :timeZone="timeZone"
        :live="true"
      /> -->

      <section class="d-flex justify-content-between">
        <table v-if="users.length">
          <tr class="text-center table-head">
            <th>ID</th>
            <th>Status</th>
            <th>Group</th>
            <th>Start</th>
            <th>End</th>
          </tr>
          <tr
            v-for="(user, index) in users"
            :key="user.id"
            :class="`table-text table-row-${index % 2}`"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.state || 'unknown' }}</td>
            <td>{{ user.group || 'None' }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>
        <div v-else>No users at the moment</div>

        <!-- <MapView
          v-if="sessionData.layout != null"
          ref="map"
          :layoutData="sessionData.layout"
          displayMode="fit"
          class="flex-grow-1 map"
          style="max-width: 600px;"
          :anchorY="0"
          :margin="50"
          :dataRate="5"
          :highlightID="selectedUserId"
          @spectator-selected="(user) => selectedUserId = user.id"
        /> -->
      </section>
    </div>
    <div v-else>Select a date to view monitoring data</div>
  </div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';
import { BSpinner } from 'bootstrap-vue';

import ServerSessionPicker from '@/components/ServerSessionPicker.vue';
import ExperienceTimeline from '@/components/monitoring/ExperienceTimeline.vue';
import MapView from '@/components/monitoring/MapView.vue';

export default {
  name: 'MonitoringPage',
  components: {
    ServerSessionPicker,
    ExperienceTimeline,
    MapView,
    BSpinner,
  },
  mixins: [
    SynapseWatcher({
      routeDynamic: 'monitoringRoute',
      dataName: 'users',
      isArray: true,
      entryIDKey: 'id',
      sorting: {
        key: 'id',
      },
    }),
  ],
  data() {
    return {
      activeSession: null,
      importantEvents: [],
      sortedGroupIds: {},

      currentTime: null,
      selectedUserId: null,

      searchText: '',

      // Events with a lower severity rating than this will not be displayed
      minimumEventSeverity: 3,
    };
  },

  mounted() {
    if (
      this.$route.params.sessionId &&
      !this.$provider.selection.serverSession
    ) {
      this.$provider
        .selectServerSession({
          localId: this.$route.params.sessionId,
        })
        .then((serverSession) => {
          this.activeSession = serverSession;
        });
    } else {
      this.activeSession = this.$provider.selection.serverSession;
      if (this.sessionData) {
        this.collectImportantEvents();
        this.sortGroups();
      }
    }
  },

  methods: {
    selectSpectator(user) {
      if (this.selectedUserId != user.id) {
        this.selectedUserId = user.id;
      } else {
        this.navigateToUser(user.id);
      }
    },

    navigateToUser(userId) {
      this.$router.push({
        name: 'one-user',
        params: {
          sessionId: this.activeSession.localId,
          userId,
        },
      });
    },

    userRowClass(user, index, selectedUserId) {
      if (selectedUserId == user.name) {
        return 'table-row-selected';
      } else if (user.sessions[0].group) {
        return `table-row-${this.sortedGroupIds[user.sessions[0].group] % 2}`;
      } else {
        return `table-row-${index % 2}`;
      }
    },

    sortGroups() {
      this.sortedGroupIds = {};
      let id = 0;
      for (let user of this.sessionData.users) {
        if (
          user.sessions[0].group &&
          this.sortedGroupIds[user.sessions[0].group] == null
        ) {
          this.sortedGroupIds[user.sessions[0].group] = id++;
        }
      }
    },

    collectImportantEvents() {
      const eventTypes = this.$provider.cache.eventTypesMap;
      this.importantEvents = [];
      if (!this.sessionData) {
        return;
      }
      for (let ev of this.sessionData.systemEvents) {
        let typeInfo = eventTypes[ev.type];
        if (typeInfo.severity >= this.minimumEventSeverity) {
          this.importantEvents.push(
            Object.assign({}, ev, {
              type: typeInfo.name,
              severity: typeInfo.severity,
              source: typeInfo.source,
            }),
          );
        }
      }
      for (let user of this.sessionData.users) {
        for (let session of user.sessions) {
          for (let ev of session.events) {
            let typeInfo = eventTypes[ev.type];
            if (typeInfo.severity >= this.minimumEventSeverity) {
              this.importantEvents.push(
                Object.assign({}, ev, {
                  user: user.name,
                  type: typeInfo.name,
                  severity: typeInfo.severity,
                  source: typeInfo.source,
                }),
              );
            }
          }
        }
      }
      this.importantEvents.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
    },

    lerp(a, b, t) {
      return a + (b - a) * t;
    },

    getUserMapData(user, time) {
      for (let session of user.sessions) {
        if (
          new Date(session.startDate).getTime() <= time &&
          (!session.endDate || new Date(session.endDate).getTime() > time)
        ) {
          // Currently active
          let obj = {
            id: user.name,
            color: '#22bbf2',
          };
          // Get current position and color
          // TEMPORARY: Get event position, instead of path position
          for (let i = 0; i < session.events.length - 1; i++) {
            let startTime = new Date(session.events[i].time).getTime();
            let endTime = new Date(session.events[i + 1].time).getTime();
            if (startTime <= time && time < endTime) {
              let startPos = JSON.parse(session.events[i].details).position;
              let endPos = JSON.parse(session.events[i + 1].details).position;
              let startRot = JSON.parse(session.events[i].details).rotation;
              let endRot = JSON.parse(session.events[i + 1].details).rotation;
              if (startPos && endPos && startRot && endRot) {
                let t = (time - startTime) / (endTime - startTime);
                obj.x = this.lerp(+startPos[0], +endPos[0], t);
                obj.y = this.lerp(+startPos[1], +endPos[1], t);
                obj.angle = this.lerp(+startRot[1], +endRot[1], t);
                return obj;
              }
            }
          }
        }
      }
      return null;
    },
  },

  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },

    timeZone() {
      return this.selectedSite.timeZone;
    },

    monitoringRoute() {
      let route =
        this.selectedSite && this.selectedSite.kitId
          ? `/sites/${this.selectedSite.kitId}/monitoring`
          : null;
      return route;
    },

    sessionData() {
      return this.$provider.cache.serverSessionData;
    },

    fetchingSessionData() {
      return this.activeSession != null && this.$provider.busy;
    },

    activeUsers() {
      let users = [];
      for (let user of this.sessionData.users) {
        if (this.searchText != '') {
          // Filter by search
          if (user.name.indexOf(this.searchText) == 0) {
            users.push(user);
          } else {
            // Look for the and group device in sessions
            for (let session of user.sessions) {
              if (session.group == this.searchText) {
                users.push(user);
                break;
              } else if (session.deviceId) {
                let device = this.$provider.cache.devicesMap[session.deviceId];
                if (device && device.name == this.searchText) {
                  users.push(user);
                  break;
                }
              }
            }
          }
        } else {
          // Filter by current time
          for (let session of user.sessions) {
            if (
              new Date(session.startDate).getTime() <= this.currentTime &&
              (!session.endDate ||
                new Date(session.endDate).getTime() > this.currentTime)
            ) {
              users.push(user);
              break;
            }
          }
        }
      }
      return users;
    },
  },

  watch: {
    activeSession(value) {
      if (this.$provider.selection.serverSession != value) {
        this.$provider.selection.serverSession = value;
      }
      if (value) {
        this.$router.push({
          name: 'monitoring-session',
          params: {
            sessionId: value.localId,
          },
        });
      } else {
        this.$router.push({
          name: 'monitoring',
        });
      }
    },
    sessionData(value) {
      this.importantEvents = [];
      if (value) {
        this.collectImportantEvents();
        this.sortGroups();
      }
    },
    currentTime(time) {
      let spectators = [];
      if (this.sessionData) {
        for (let user of this.sessionData.users) {
          let obj = this.getUserMapData(user, time);
          if (obj) {
            spectators.push(obj);
          }
        }
      }
      this.$refs['map'].setSpectators(spectators);
    },
  },
};
</script>

<style scoped>
.subheading {
  font-size: 14px;
  color: var(--subheader);
}

th {
  padding: 2px 8px;
}

.monitoring-table-container {
  min-width: 380px;
  height: 600px;
  overflow-y: scroll;
  padding-right: 5px;
}

.map {
  overflow: hidden;
}

.table-text {
  font-size: 0.75rem;
  line-height: 1rem;
}

.table-head {
  background-color: var(--container);
}

.table-row-0 {
  background-color: var(--black);
}

.table-row-1 {
  background-color: var(--panel);
}

.table-row-selected {
  background-color: var(--borders);
}

.hint {
  color: var(--light-grey);
  font-size: 11px;
  text-align: center;
  min-width: 80%;
  margin: auto;
  width: min-content;
  word-wrap: break-word;
}
</style>
