<template>
  <div class="table-wrapper w-100 h-100 px-3 pt-3">
    <div v-if="selectedSite.kitId">
      <SelectInput
        v-if="miscFields.length"
        v-model="enabledMiscFields"
        class="mb-3"
        :options="miscFields"
        placeholder="Extra fields to display"
      />
      <!-- <b-form-checkbox
        v-model="hideUnused"
        class="ml-2"
      /> -->
    </div>
    <table class="w-100">
      <tbody>
        <tr class="text-center cursor-pointer table-head">
          <th class="clickable" @click="setSortField('name')">
            Identity<span v-if="sortingField == 'name'">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
          <th class="clickable" @click="setSortField('mac')">
            MAC<span v-if="sortingField == 'mac'">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
          <th class="clickable" @click="setSortField('runPct')">
            Success rate<span v-if="sortingField == 'runPct'">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
          <th class="clickable" @click="setSortField('created')">
            Created on<span v-if="sortingField == 'created'">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
          <th class="clickable" @click="setSortField('lastSeen')">
            Status<span v-if="sortingField == 'lastSeen'">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
          <th
            v-for="key in enabledMiscFields"
            :key="key"
            class="clickable"
            @click="setSortField(`misc/${key}`)"
          >
            {{ key
            }}<span v-if="sortingField == `misc/${key}`">
              {{ sortingReversed ? '↑' : '↓' }}</span
            >
          </th>
        </tr>
        <tr
          v-for="(device, index) in sortedInventory"
          :key="`${device.id}_${device.name}_${device.isOnline}_${device.misc.lastSeen}`"
          :class="`clickable table-text table-row-${index % 2} ${device.runPct <= 0 ? 'table-fade' : ''}`"
          @click="navigateToDevice(device.id)"
        >
          <td>
            <img
              style="height: 2rem"
              :src="`/devices/${device.type.toLowerCase()}.png`"
              onerror="this.onerror = null; this.remove();"
            />
            {{ device.type }} {{ device.name }} ({{ device.id }})
          </td>
          <td>{{ device.mac }}</td>
          <td>
            {{
              isNaN(device.runPct)
                ? 'unknown'
                : (device.runPct * 100).toFixed(2) + '%'
            }}
          </td>
          <td>
            {{
              new Date(device.firstDate).toLocaleString('en-US', {
                timeZone: selectedSite.timeZone,
              })
            }}
          </td>
          <td v-if="device.misc.isOnline">Online</td>
          <td v-else-if="device.misc.lastSeen">
            Last seen:
            {{
              new Date(device.misc.lastSeen).toLocaleString('en-US', {
                timeZone: selectedSite.timeZone,
              })
            }}
          </td>
          <td v-else>Unknown</td>
          <td v-for="key in enabledMiscFields" :key="key">
            {{ device.misc[key] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';
import SelectInput from '@/components/event-log/SelectInput.vue';

export default {
  name: 'InventoryPage',
  components: { SelectInput },
  mixins: [
    SynapseWatcher({
      routeDynamic: 'inventoryRoute',
      dataName: 'liveDevices',
      isArray: true,
      entryIDKey: 'id',
      sorting: {
        key: 'name',
      },
    }),
  ],
  data() {
    return {
      sortingField: 'name',
      sortingReversed: false,

      enabledMiscFields: [],
      hideUnused: true,
    };
  },
  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },

    inventoryRoute() {
      let route =
        this.selectedSite && this.selectedSite.kitId
          ? `/sites/${this.selectedSite.kitId}/inventory`
          : null;
      return route;
    },

    miscFields() {
      let fields = [];
      for (let device of this.liveDevices) {
        if (device.misc) {
          for (let key in device.misc) {
            if (
              key != 'lastSeen' &&
              key != 'isOnline' &&
              fields.indexOf(key) < 0
            ) {
              fields.push(key);
            }
          }
        }
      }
      return fields;
    },

    cachedDevices() {
      if (this.$provider.cache.devices) {
        return this.$provider.cache.devices.map((device) => ({
          id: device.localId,
          type: device.type,
          name: device.name,
          mac: device.macAddress,
          runPct: device.stats.runsWithoutIncident,
          firstDate: device.firstDate,
          misc: {},
        }));
      }
      return [];
    },

    sortedInventory() {
      let array = this.liveDevices.length
        ? this.liveDevices.slice()
        : this.cachedDevices;
      let sortWithMiscData = this.sortingField.startsWith('misc/');
      let sortingField = sortWithMiscData
        ? this.sortingField.substring(5)
        : this.sortingField;
      array.sort((a, b) => {
        let val = 0;
        let aa = sortWithMiscData ? a.misc : a;
        let bb = sortWithMiscData ? b.misc : b;
        if (aa[sortingField] != bb[sortingField]) {
          if (!isNaN(aa[sortingField]) && !isNaN(bb[sortingField])) {
            val = aa[sortingField] - bb[sortingField];
          } else {
            val = (aa[sortingField] || '') > (bb[sortingField] || '') ? 1 : -1;
          }
        } else if (a.type != b.type) {
          val = a.type > b.type ? 1 : -1;
        } else if (a.name != b.name) {
          val = a.name > b.name ? 1 : -1;
        }
        if (this.sortingReversed) {
          val = -val;
        }
        return val;
      });
      return array;
    },
  },
  methods: {
    setSortField(field) {
      if (this.sortingField == field) {
        this.sortingReversed = !this.sortingReversed;
      } else {
        this.sortingField = field;
        this.sortingReversed = false;
      }
    },

    navigateToDevice(deviceId) {
      this.$router.push({
        name: 'one-device',
        params: { deviceId },
      });
    },

    imgError(ev) {
      console.log('error', ev);
    },
  },
};
</script>

<style scoped>
.table-text {
  font-size: 0.75rem;
  line-height: 1rem;
}

.table-head {
  background-color: var(--container);
}

.table-row-0 {
  background-color: var(--black);
}

.table-row-1 {
  background-color: var(--panel);
}

.clickable {
  cursor: pointer;
}

.table-fade {
  opacity: 50%;
}

.clickable:hover {
  background-color: var(--borders);
}
</style>
