<template>
  <div class="dropdown">
    <button class="dropbtn" @click="toggleDropdown">
      <span class="button-content">
        {{ profileName }}
        <font-awesome-icon :icon="iconName" class="icon" />
      </span>
    </button>

    <div class="dropdown-content" v-if="isOpen">
      <a
        v-for="(option, index) in options"
        :key="index"
        @click="handleOptionClick(option)"
        :class="{ 'logout-btn': option === 'Logout' }"
        >{{ option }}</a
      >
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'UserInfo',

  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      isOpen: false,
      options: ['Settings', 'Logout'],
    };
  },

  computed: {
    iconName() {
      return this.isOpen ? ['fas', 'angle-up'] : ['fas', 'angle-down'];
    },

    profileName() {
      if (this.$provider.cache.profile) {
        return this.$provider.cache.profile.name;
      }
      return 'Menu';
    },
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    handleOptionClick(option) {
      if (option === 'Settings') {
        // TODO: Go to settings page
        alert('Not yet implemented');
      } else if (option === 'Logout') {
        this.$auth.logout();
      }
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.dropbtn {
  position: relative;
  background-color: var(--black);
  color: var(--white);
  padding: 5px 8px 5px 8px;
  font-size: 11px;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-content {
  position: absolute;
  background-color: #000;
  right: 0;
  z-index: 100;
  border: 1px solid var(--borders);
  border-radius: 4px;
}

.dropdown-content a {
  color: var(--white);
  padding: 12px 16px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: var(--dark-grey);
  cursor: pointer;
}

.dropdown-content .logout-btn {
  color: var(--danger);
}

.dropbtn:disabled {
  background-color: var(--dark-grey);
  color: var(--light-grey);
  cursor: not-allowed;
}

.button-content {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 5px;
}
</style>
