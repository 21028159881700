const backgroundColors = [
  'rgb(255, 165, 0)',
  'rgb(70, 130, 180)',
  'rgb(34, 139, 34)',
  'rgb(255, 69, 0)',
  'rgb(0, 128, 128)',
  'rgb(128, 0, 128)',
  'rgb(255, 0, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 0, 255)',
  'rgb(255, 20, 147)',
  'rgb(0, 128, 255)',
  'rgb(128, 0, 0)',
  'rgb(255, 99, 71)',
  'rgb(0, 255, 127)',
  'rgb(255, 255, 0)',
];

// for one device session chart colors
const colors = [
  '#F7CD4D', // Yellow
  '#F09D5D', // Orange
  '#94ED64', // Green
  '#F74D50', // Red
  '#4D97F7', // Blue
  '#59D4D9', // Pink
  '#C881F7', // Violet
];

function getHourAndMinuteFromTimestamp(timestamp) {
  const montrealTime = new Date(timestamp);
  const hour = montrealTime.getUTCHours();
  const minute = montrealTime.getUTCMinutes();

  // Add leading zeros if needed
  const formattedHour = hour < 10 ? `0${hour}` : hour.toString();
  const formattedMinute = minute < 10 ? `0${minute}` : minute.toString();

  return `${formattedHour}:${formattedMinute}`;
}

function formatDate(timestamp) {
  // const year = timestamp.getFullYear();
  const day = timestamp.getDate().toString().padStart(2, '0');
  const hours = timestamp.getHours().toString().padStart(2, '0');
  const minutes = timestamp.getMinutes().toString().padStart(2, '0');
  const month = timestamp.toLocaleString('default', { month: 'short' });
  return `${month} ${day} ${hours}:${minutes}`;
}

function fillGapTimeGroupByNone(interval, fromDate, toDate) {
  const filledTime = [];

  let currentTime = new Date(fromDate);
  const endDateTime = new Date(toDate);

  // Initialize the filledMap with zero values for all time slots within the range
  while (currentTime <= endDateTime) {
    filledTime.push(currentTime.getTime());
    currentTime.setMinutes(currentTime.getMinutes() + interval);
  }

  return filledTime;
}

function fillGapTimeGroupByTimeOfTheDay(intervalCountMap, interval) {
  const filledMap = {};

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const timeKey = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      filledMap[timeKey] = intervalCountMap[timeKey] || 0;
    }
  }

  return filledMap;
}

function convertTimestampToMinutes(str) {
  if (!str) return 0;

  // Check if the input string is in the format "00:00"
  if (str.match(/^\d{2}:\d{2}$/)) {
    const hour = str.split(':')[0];
    const minute = str.split(':')[1];
    const totalMinutes = Number(hour) * 60 + Number(minute);
    return totalMinutes;
  }

  // Check if the input string is in the format "MMM DD 00:00"
  if (str.match(/^\w{3}\s\d{2}\s\d{2}:\d{2}$/)) {
    const day = str.split(' ')[1];
    const timePart = str.split(' ')[2];
    const hour = timePart.split(':')[0];
    const minute = timePart.split(':')[1];

    const totalMinutes =
      (Number(day) - 1) * 24 * 60 + Number(hour) * 60 + Number(minute);
    return totalMinutes;
  }

  return 0;
}

// groupBy = none
function getDataByDays(
  data,
  eventTypes,
  interval,
  fromDate,
  toDate,
  toZonedTime,
  timeZone,
) {
  const timeSlots = fillGapTimeGroupByNone(interval, fromDate, toDate);

  const labels = timeSlots.map((slot) =>
    formatDate(toZonedTime(slot, timeZone)),
  );

  const gap = toDate.getTime() - fromDate.getTime();

  const datasets = [];
  data.forEach((item, index) => {
    // Each item is a dataset grouped by event type
    const eventType = eventTypes[item.eventTypeId];
    const timestamps = item.events.flatMap((event) => event.timestamps);
    const countPerTimeSlot = new Array(timeSlots.length).fill(0);

    for (let timestamp of timestamps) {
      const t = (new Date(timestamp).getTime() - fromDate.getTime()) / gap;
      let index = Math.floor(t * timeSlots.length);
      if (index == timeSlots.length) {
        // Fix case where timestamp == toDate
        index--;
      }
      countPerTimeSlot[index]++;
    }

    datasets.push({
      label: eventType.name,
      data: countPerTimeSlot,
      backgroundColor: backgroundColors[index % backgroundColors.length],
    });
  });

  return { datasets, labels };
}

// groupBy = timeOfTheDay
function getDataByHours(data, eventTypes, interval, toZonedTime, timeZone) {
  const datasets = [];
  let labels = [];
  data.forEach((item, index) => {
    const eventType = eventTypes[item.eventTypeId];
    const timestamps = item.events.flatMap((event) => event.timestamps);

    const getTimestampByInterval = (timestamp, interval) => {
      const date = toZonedTime(timestamp, timeZone);
      const formattedMinute =
        Math.floor(date.getMinutes() / interval) * interval;

      return `${date.getHours().toString().padStart(2, '0')}:${formattedMinute
        .toString()
        .padStart(2, '0')}`;
    };

    const intervalCountMap = timestamps.reduce((counts, timestamp) => {
      const timeKey = getTimestampByInterval(timestamp, interval);
      counts[timeKey] = (counts[timeKey] || 0) + 1;
      return counts;
    }, {});

    const filledIntervalCountMap = fillGapTimeGroupByTimeOfTheDay(
      intervalCountMap,
      interval,
    );
    const intervalLabels = Object.keys(filledIntervalCountMap)
      .sort((a, b) => a.localeCompare(b))
      .map((interval) => interval);

    const dataGroupByTime = intervalLabels.map(
      (intervalLabel) => filledIntervalCountMap[intervalLabel],
    );

    datasets.push({
      label: eventType.name,
      data: dataGroupByTime,
      backgroundColor: backgroundColors[index % backgroundColors.length],
    });

    labels = intervalLabels;
  });

  return { datasets, labels };
}

// groupBy = device
function getDataByDevices(data, eventTypes, selectedDevices, deviceMap) {
  const datasets = [];
  let labels = [];

  data.forEach((item) => {
    const eventType = eventTypes[item.eventTypeId];
    const eventCounts = {};

    item.events.forEach((event) => {
      const deviceName = deviceMap[event.deviceId]?.name || 'none';
      const timestampsCount = event.timestamps.length;

      if (eventCounts[deviceName] != null) {
        eventCounts[deviceName] += timestampsCount;
      } else {
        eventCounts[deviceName] = timestampsCount;
      }

      if (!labels.includes(deviceName)) {
        labels.push(deviceName);
      }
    });

    const sortedLabels = labels.sort((a, b) => {
      if (isNaN(a) || isNaN(b)) {
        if (a > b) {
          return 1;
        } else {
          return -1;
        }
      } else {
        const aNum = parseInt(a);
        const bNum = parseInt(b);
        return aNum - bNum;
      }
    });

    const dataValues = sortedLabels.map((label) => eventCounts[label] || 0);

    labels.forEach((label) => {
      if (!sortedLabels.includes(label)) {
        sortedLabels.push(label);
        dataValues.push(0);
      }
    });

    datasets.push({
      label: eventType.name,
      data: dataValues,
      backgroundColor:
        backgroundColors[datasets.length % backgroundColors.length],
    });

    labels = sortedLabels;
  });

  selectedDevices.forEach((device) => {
    if (!labels.includes(device.name)) {
      labels.push(device.name);
      datasets.forEach((dataset) => {
        dataset.data.push(0);
      });
    }
  });

  return { datasets, labels };
}

// groupBy = user
function getDataByUsers(data, eventTypes, selectedUserNames) {
  const datasets = [];
  let labels = [];

  data.forEach((item) => {
    const eventType = eventTypes[item.eventTypeId];
    const userCounts = {};

    item.events.forEach((event) => {
      const userName = event.experienceLocalId || 'none';
      const timestampsCount = event.timestamps.length;

      if (userCounts[userName] != null) {
        userCounts[userName] += timestampsCount;
      } else {
        userCounts[userName] = timestampsCount;
      }

      if (!labels.includes(userName)) {
        labels.push(userName);
      }
    });

    const sortedLabels = labels.sort((a, b) => {
      if (isNaN(a) || isNaN(b)) {
        if (a > b) {
          return 1;
        } else {
          return -1;
        }
      } else {
        const aNum = parseInt(a);
        const bNum = parseInt(b);
        return aNum - bNum;
      }
    });

    const dataValues = sortedLabels.map((label) => userCounts[label] || 0);

    labels.forEach((label) => {
      if (!sortedLabels.includes(label)) {
        sortedLabels.push(label);
        dataValues.push(0);
      }
    });

    datasets.push({
      label: eventType.name,
      data: dataValues,
      backgroundColor:
        backgroundColors[datasets.length % backgroundColors.length],
    });

    labels = sortedLabels;
  });

  selectedUserNames.forEach((userName) => {
    if (!labels.includes(userName)) {
      labels.push(userName);
      datasets.forEach((dataset) => {
        dataset.data.push(0);
      });
    }
  });

  return { datasets, labels };
}

function getXYValues(points, axis) {
  const axisMap = { x: 0, y: 1 };
  const result = points.map((dataPoint) => dataPoint[axisMap[axis]]);
  return result;
}

function populateXYDefaultValues(start, stop, step) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step,
  );
}

function updateTimeElapsed(date) {
  const now = new Date();
  const commentDate = new Date(date);
  const diffMs = now - commentDate;
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);

  if (diffMonths > 0) {
    return diffMonths + (diffMonths === 1 ? ' month' : ' months');
  } else if (diffDays > 0) {
    return diffDays + (diffDays === 1 ? ' day' : ' days');
  } else if (diffHours > 0) {
    return diffHours + (diffHours === 1 ? ' hour' : ' hours');
  } else if (diffMinutes > 0) {
    return diffMinutes + (diffMinutes === 1 ? ' minute' : ' minutes');
  } else {
    return diffSeconds + (diffSeconds === 1 ? ' second' : ' seconds');
  }
}

module.exports = {
  getHourAndMinuteFromTimestamp,
  getDataByDays,
  getDataByHours,
  getDataByDevices,
  getDataByUsers,
  convertTimestampToMinutes,
  getXYValues,
  populateXYDefaultValues,
  colors,
  updateTimeElapsed,
};
