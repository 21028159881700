<template>
  <div>
    <HintMessage
      class="align-left"
      v-if="parsedRows.length === 0"
      :isNoDataHint="true"
      :displayName="displayName"
    />

    <table v-else class="mb-4 text-center">
      <thead>
        <tr>
          <th v-for="header in adjustedHeaders" :key="header">
            {{ header }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template>
          <tr
            v-for="(row, rowIndex) in parsedRows"
            :key="rowIndex"
            :style="{ color: getRowColor(rowIndex) }"
            :class="`clickable table-row-${rowIndex % 2}`"
            @click="navigateToDevice(row.localId)"
          >
            <!-- Align in the middle -->
            <!-- <td
              v-for="(cell, cellIndex) in row"
              :key="cellIndex"
              v-html="cell"
            /> -->

            <!-- Align device info to the left, others in the center -->
            <td class="text-left" v-html="row[0]" />
            <td
              v-for="(cell, cellIndex) in row.slice(1)"
              :key="cellIndex + 1"
              v-html="cell"
            />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Parser } from 'expr-eval';
import HintMessage from '@/components/HintMessage.vue';

export default {
  name: 'DashboardDeviceTable',
  components: { HintMessage },
  props: {
    title: String,
    headers: Array,
    values: Array,
    apiData: Object,
    data: String,
    showDeviceId: Boolean,
    displayName: String,
  },
  computed: {
    cachedDevices() {
      if (this.$provider.cache.devices) {
        return this.$provider.cache.devices.map((device) => ({
          id: device.localId,
          type: device.type,
          name: device.name,
        }));
      }
      return [];
    },

    adjustedHeaders() {
      if (this.showDeviceId) {
        return [this.displayName, ...this.headers];
      }
      return this.headers;
    },

    parsedRows() {
      if (!this.apiData || !this.apiData.content) return [];

      return (
        this.apiData.content[this.data]?.map((deviceData) => {
          let row = this.values.map((expression) =>
            this.evaluateExpression(expression, deviceData),
          );

          if (this.showDeviceId) {
            const device = this.cachedDevices.find(
              (d) => d.id === deviceData.localId,
            );

            const deviceInfo = device
              ? `<img style="height: 2rem" src="/devices/${device.type.toLowerCase()}.png" /> ${
                  device.type
                } ${device.name} (${device.id})`
              : deviceData.localId;

            row = [deviceInfo, ...row];
          }
          row.localId = deviceData.localId;
          return row;
        }) || []
      );
    },
  },

  methods: {
    evaluateExpression(expression, deviceData) {
      if (expression.startsWith('#') && expression.endsWith('#')) {
        const parsedExpression = expression.slice(1, -1);
        const parser = new Parser();

        try {
          const result = parser.evaluate(parsedExpression, deviceData);
          return parsedExpression.includes('/')
            ? this.formatPercentage(result)
            : result;
        } catch (e) {
          console.error(`Error evaluating expression: ${expression}`, e);
          return 'Error';
        }
      }
      return expression;
    },

    formatPercentage(value) {
      return (value * 100).toFixed(2) + '%';
    },

    getRowColor(rowIndex) {
      return this.colors && this.colors.length > rowIndex
        ? this.colors[rowIndex]
        : 'inherit';
    },

    navigateToDevice(deviceId) {
      this.$router.push({
        name: 'one-device',
        params: { deviceId },
      });
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: var(--borders);
}

.table-row-0 {
  background-color: var(--black);
}

.table-row-1 {
  background-color: var(--panel);
}

.align-left {
  /* um...important? ╮(╯_╰)╭ */
  text-align: left !important;
}
</style>
