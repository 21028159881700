<template>
  <div>
    <div>{{ displayName }}</div>

    <div v-if="!apiData || apiData.length === 0">
      <HintMessage :isNoDataHint="showNoDataHint" />
    </div>

    <div v-else>
      <div v-if="this.apiDataMode === 'line'" class="line-chart mb-4">
        <LineChart :data="lineChartData" :options="lineChartOptions" />
      </div>

      <div v-if="this.apiDataMode === 'combo'" class="bar-chart mb-4">
        <Bar :data="barChartData" :options="barChartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Filler,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'vue-chartjs';
import { enUS } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(Filler, zoomPlugin, ArcElement, Tooltip, Legend);
import { dashboardTemplate } from '@/helper/dashboardTemplate.js';
import HintMessage from '@/components/HintMessage.vue';

export default {
  name: 'DashboardMetric',
  components: {
    LineChart: Line,
    Bar,
    HintMessage,
  },

  props: {
    startDate: Date,
    endDate: Date,
    siteId: String,
    metricName: String,
    displayName: String,
    type: String,
    unit: String,
    min: Number,
    max: Number,
  },

  data() {
    return {
      dashboardTemplate,
      apiData: null,
      apiDataMode: null,
      showNoDataHint: false,
      lineChartData: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      lineChartOptions: {
        responsive: true,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              minUnit: 'minute',
            },
          },
          y: {
            type: 'linear',
            min: 15,
            max: 30,
            beginAtZero: false,
            ticks: {
              maxTicksLimit: 5,
            },
          },
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0, // hide the dots
          },
        },
        parsing: {
          xAxisKey: 'time',
          yAxisKey: 'value',
        },
      },
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const datasetLabel = context.dataset.label || '';
                const data = context.raw;
                let value;

                if (datasetLabel === 'Min') {
                  value = data[0];
                } else if (datasetLabel === 'Max') {
                  value = data[1];
                } else if (datasetLabel === 'Avg') {
                  value = data;
                }

                return `${datasetLabel}: ${value}`;
              },
            },
          },
        },

        scales: {
          x: {
            stacked: true,
            type: 'time',
            time: {
              minUnit: 'minute',
            },
          },
          y: {
            stacked: false,
          },
        },
      },
    };
  },

  methods: {
    formatTZ(time) {
      return toZonedTime(time, this.$provider.selection.site.timeZone);
    },

    formatApiData() {
      let formattedApiData = [];

      if (!this.apiData || this.apiData.length === 0) return;

      for (let i = 0; i < this.apiData.length; i++) {
        let m = this.apiData[i];
        let m2 = this.apiData[i + 1];

        formattedApiData.push({
          value: m.value,
          time: this.formatTZ(m.startDate),
          color: m.color,
        });

        if (
          m2 &&
          m2.value != null &&
          new Date(m2.startDate) - new Date(m.endDate) > 10000
        ) {
          formattedApiData.push({
            value: m.value,
            time: this.formatTZ(m.endDate),
            color: m.color,
          });
          formattedApiData.push({
            value: null,
            time: this.formatTZ(new Date(m.endDate).getTime() + 5000),
            color: m.color,
          });
        }
      }

      let lastStatus = this.apiData[this.apiData.length - 1];
      if (lastStatus) {
        formattedApiData.push({
          value: lastStatus.value,
          time: this.formatTZ(lastStatus.endDate || this.endDate),
          color: lastStatus.color,
        });
      }
      return formattedApiData;
    },

    setLineChartData(formattedApiData) {
      if (!this.apiData || this.apiData.length === 0) return;

      this.lineChartData = {
        labels: formattedApiData.map((m) => m.time),
        datasets: [
          {
            data: formattedApiData,
            segment: {
              borderColor: (ctx) => ctx.p1.raw.color,
            },
            type: 'line',
            borderWidth: 1,
          },
        ],
      };
    },

    processChartData() {
      let formattedApiData = this.formatApiData();
      this.setLineChartData(formattedApiData);
    },

    setBarChartData() {
      if (!this.apiData || this.apiData.length === 0) return;

      const labels = this.apiData.map((item) => this.formatTZ(item.startDate));

      const avgData = this.apiData.map((item) => Number(item.avg));
      const min = this.apiData.map((item) => Number(item.min));
      const max = this.apiData.map((item) => Number(item.max));

      const minData = [];
      const maxData = [];
      for (let i = 0; i < this.apiData.length; i++) {
        minData[i] = [min[i], avgData[i]];
        maxData[i] = [avgData[i], max[i]];
      }

      // set min and max in between 15 - 30 since we know it's temperature
      // if there's no min or max, get real min and max dynamically
      const globalMin = this.min !== undefined ? this.min : Math.min(...min);
      const globalMax = this.max !== undefined ? this.max : Math.max(...max);

      this.barChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Min',
            data: minData,
            borderColor: 'green',
            backgroundColor: 'rgba(75, 192, 192, 0.8)',
            order: 1,
          },
          {
            label: 'Max',
            data: maxData,
            borderColor: 'red',
            backgroundColor: 'rgba(255, 99, 132, 0.8)',
            order: 1,
          },
          {
            label: 'Avg',
            data: avgData,
            borderColor: 'rgba(255, 223, 153, 1)',
            type: 'line',
            borderWidth: 2,
            order: 0,
          },
        ],
      };

      this.barChartOptions.scales.y.min = globalMin;
      this.barChartOptions.scales.y.max = globalMax;
    },
  },

  mounted() {
    this.$ynapse
      .GET('/api/v1/dashboard/metrics', {
        siteId: this.siteId,
        startDate: this.startDate,
        endDate: this.endDate,
        metricName: this.metricName,
      })
      .then((res) => {
        this.apiData = res.data.data;
        this.apiDataMode = res.data.mode;

        if (!this.apiData || this.apiData.length === 0) {
          this.showNoDataHint = true;
        } else {
          this.processChartData();
          this.setBarChartData();
        }
      });
  },
};
</script>

<style scoped>
.line-chart {
  min-width: 320px;
  max-width: 600px;
}

.bar-chart {
  min-width: 480px;
  max-width: 600px;
}
</style>
