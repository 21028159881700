<template>
  <div>
    <HintMessage
      v-if="showLoadingMessage || showNoDataHint"
      :isLoadingHint="showLoadingMessage"
      :isNoDataHint="showNoDataHint"
    />
    <div class="event-table-container" v-if="!showLoadingMessage && !showNoDataHint">
      <table class="w-100">
        <thead>
          <tr>
            <th class="header">
              <div @click="handleSortByColumn('eventType')">Event type</div>
            </th>
            <th class="header">
              <div @click="handleSortByColumn('device')">Device</div>
            </th>
            <th class="header">
              <div @click="handleSortByColumn('user')">User</div>
            </th>
            <th class="header">
              <div @click="handleSortByColumn('time')">Time</div>
            </th>
            <th class="d-flex justify-content-between align-items-center">
              <span>Details</span>
              <div class="check">
                Display mode:
                <b-button variant="primary" size="sm" @click="cycleDisplayMode">
                  {{detailsDisplayMode}}
                </b-button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, index) in eventData" :key="index">
            <td>{{ $provider.cache.eventTypesMap[event.eventTypeId].name }}</td>
            <td>{{ event.deviceId ? $provider.cache.devicesMap[event.deviceId].name : 'none' }}</td>
            <td>{{ event.experienceLocalId || 'none' }}</td>
            <td>{{
              new Date(event.timestamp).toLocaleString('en-US', {timeZone})
            }}</td>
            <td v-if="detailsDisplayMode == 'summary'">
              <div class="summary">{{ detailsSummary(event.details) }}</div>
            </td>
            <td v-else-if="detailsDisplayMode == 'table'" class="p-0">
              <table class="w-100">
                <tr
                  v-for="(value, key) in cleanDetails(event.details)"
                  :key="key"
                >
                  <th>{{key}}</th>
                  <td>{{value}}</td>
                </tr>
              </table>
            </td>
            <td v-else-if="detailsDisplayMode == 'lines'">
              <div
                v-for="(value, key) in cleanDetails(event.details)"
                :key="key"
              >
                {{ key }} = {{ value }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-3 d-flex align-items-center justify-content-center">
        <button
          @click="handlePageNavigation('first')"
          :disabled="currentPage <= 1"
          class="btn btn-warning mx-3"
        >
          &lt;&lt;
        </button>
        <button
          @click="handlePageNavigation('prev')"
          :disabled="currentPage <= 1"
          class="btn btn-warning mx-3"
        >
          &lt;
        </button>
        <div>{{ currentPage }}</div>
        &nbsp;/&nbsp;
        <div>{{ totalPages }}</div>
        <button
          @click="handlePageNavigation('next')"
          :disabled="currentPage >= totalPages"
          class="btn btn-warning mx-3"
        >
          &gt;
        </button>
        <button
          @click="handlePageNavigation('last')"
          :disabled="currentPage >= totalPages"
          class="btn btn-warning mx-3"
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HintMessage from '@/components/HintMessage.vue';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'EventTable',
  props: {
    apiData: Array,
    itemsPerPage: Number,
    currentPage: Number,
    totalPages: Number,
    sortBy: String,
    sortOrder: String,
    timeZone: String,
  },

  components: { HintMessage, BButton },

  data() {
    return {
      eventData: [],
      showLoadingMessage: false,
      showNoDataHint: false,

      detailsDisplayMode: 'summary',
    };
  },

  watch: {
    apiData: {
      handler: 'updateEventData',
      immediate: true,
    },
  },
  methods: {
    updateEventData(newData) {
      this.showLoadingMessage = false;
      if (newData[0].events.length === 0) {
        this.showNoDataHint = true;
      } else {
        this.eventData = newData[0].events;
      }
    },

    cleanDetails(details) {
      let obj = JSON.parse(details);
      delete obj.position;
      delete obj.rotation;
      return obj;
    },

    detailsSummary(details) {
      let array = [];
      let obj = JSON.parse(details);
      for (let key in obj) {
        if (key != 'position' && key != 'rotation') {
          array.push(`${key} = ${obj[key]}`);
        }
      }
      return array.join(', ');
    },

    handlePageNavigation(direction) {
      this.showLoadingMessage = true;

      this.$emit('loadPage', direction);
    },

    handleSortByColumn(column) {
      this.showLoadingMessage = true;
      this.$emit('sortByColumn', column);
    },

    cycleDisplayMode() {
      if (this.detailsDisplayMode == 'summary') {
        this.detailsDisplayMode = 'lines';
      } else if (this.detailsDisplayMode == 'lines') {
        this.detailsDisplayMode = 'table';
      } else if (this.detailsDisplayMode == 'table') {
        this.detailsDisplayMode = 'summary';
      }
    }
  },
};
</script>

<style scoped>
.event-table-container {
  overflow-x: auto;
}

.header:hover {
  background-color: var(--dark-grey);
  cursor: pointer;
}

.summary {
  max-width: 40vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.check {
  font-size: 11px;
  color: var(--content);
}

th:last-child,
td:last-child {
  word-wrap: break-word;
}

@media (min-width: 1440px) {
  .event-table-container {
    overflow-x: unset !important;
  }

  table {
    width: unset !important;
  }

  th.header,
  td {
    word-wrap: unset !important;
  }
}
</style>
