var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedMetric)?_c('div',[_c('header',[_c('div',{staticClass:"subheading"},[_c('router-link',{attrs:{"to":{ name: 'system-metrics' }}},[_vm._v(" < "+_vm._s(_vm.categoryName)+" ")])],1),_c('div',{staticClass:"header-container"},[_c('h2',[_vm._v(_vm._s(_vm.selectedMetric.title || _vm.selectedMetric.name))]),_c('DateTimeFilter',{on:{"date-range-updated":_vm.handleDateRangeSelected,"date-option-updated":_vm.handleDateOptionSelected}})],1)]),(
      _vm.showLoadingMessage || _vm.showNoDataHint || _vm.showRequiredFieldsHintDetails
    )?_c('HintMessage',{attrs:{"isLoadingHint":_vm.showLoadingMessage,"isNoDataHint":_vm.showNoDataHint,"isRequiredFieldsHintDetails":_vm.showRequiredFieldsHintDetails}}):_vm._e(),(
      !_vm.showRequiredFieldsHintDetails && !_vm.showNoDataHint && !_vm.showLoadingMessage
    )?_c('section',[(_vm.selectedMetric.type === 'number')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"temperature-info"},[_c('div',{staticClass:"subheading"},[_vm._v("Low")]),_c('h2',[_vm._v(_vm._s(_vm.low)+_vm._s(_vm.selectedMetric.unit))])]),_c('div',{staticClass:"temperature-info"},[_c('div',{staticClass:"subheading"},[_vm._v("High")]),_c('h2',[_vm._v(_vm._s(_vm.high)+_vm._s(_vm.selectedMetric.unit))])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"line-chart mx-5"},[(
        !_vm.showLoadingMessage &&
        !_vm.showNoDataHint &&
        !_vm.showRequiredFieldsHintDetails
      )?_c('LineChart',{attrs:{"data":_vm.chartData,"options":_vm.chartOptions}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }