<template>
  <SelectInput
    v-model="selectedEventsOrMetricsInternal"
    class="w-100"
    :options="metricAndEventOptions"
    :placeholder="metricAndEventPlaceholder"
    @input="emitSelection"
    :selectable="(option) => !option.disabled"
    :marginRight="marginRight"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SelectInput from '@/components/event-log/SelectInput.vue';
import { colors } from '@/helper/utils.js';

export default {
  name: 'MetricAndEventSelectInput',
  components: {
    SelectInput,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: String,
    minWidth: String,
    marginRight: {
      type: String,
      default: '10px',
    },
  },

  data() {
    return {
      shouldApplyMargin: true,
      selectedEventsOrMetricsInternal: [],
      metricAndEventPlaceholder: 'Search event type or metric',
    };
  },

  mounted() {
    this.refreshSelection();
  },

  watch: {
    metricAndEventOptions() {
      this.refreshSelection();
    },
  },

  computed: {
    ...mapState(['selectionsInOneDevice']),

    metricAndEventOptions() {
      const metrics = this.$provider.cache.metrics || [];
      const eventTypes = this.$provider.cache.eventTypes || [];

      const metricsData = metrics
        .map((metric) => ({
          ...metric,
          category: 'metric',
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      const eventTypesData = eventTypes
        .map((eventType) => ({
          ...eventType,
          category: 'eventType',
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      const firstMetric = {
        name: 'Choose A Metric',
        id: 'Choose A Metric',
        disabled: true,
      };

      const firstEvent = {
        name: 'Choose A Event',
        id: 'Choose A Event',
        disabled: true,
      };

      metricsData.unshift(firstMetric);
      eventTypesData.unshift(firstEvent);

      return [...metricsData, ...eventTypesData];
    },
  },

  methods: {
    ...mapMutations(['updateSelectionsInOneDevice']),

    refreshSelection() {
      this.selectedEventsOrMetricsInternal =
        this.selectionsInOneDevice.selections || [];
    },

    emitSelection() {
      const selections = this.selectedEventsOrMetricsInternal.map(
        (selection, index) => {
          const colorIndex = index % colors.length;
          return {
            ...selection,
            color: colors[colorIndex],
          };
        },
      );

      this.updateSelectionsInOneDevice({
        projectName: this.$provider.selection.site.projectName,
        selections: selections,
      });
    },
  },
};
</script>
